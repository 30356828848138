@use "./utilities/base" as *;
@use "./utilities/colors" as colors;
@use "./utilities/fonts" as fonts;
@use "./utilities/mixins" as mixin;

#home {
    .hero {
        width: 100%;
        height: 100vh;
        background: linear-gradient(
            90deg,
            colors.get("orange", dark),
            colors.get("blue", dark)
        );
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        border-bottom: 30px solid colors.get("dark", dark);
        #bg-img-1 {
            position: absolute;
            object-fit: cover;
            top: 0;
            right: 0;
            opacity: 0.3;
            width: 100%;
            height: 100vh;
            transform: rotate(180deg);
        }
        #bg-img-2 {
            position: absolute;
            object-fit: cover;
            top: 0;
            right: 0;
            opacity: 0.4;
            width: 100%;
            height: 100vh;
            filter: blur(3px);
            transform: rotate(180deg);
        }
        .container {
            width: 100%;
            height: calc(100% - 110px);
            padding-top: 110px;
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            z-index: 100;
            .hero-title {
                background-color: colors.getColorWithOpacity("dark", dark, 0.3);
                padding: 20px;
                padding-left: 30px;
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                h1 {
                    color: colors.get("orange");
                    font-weight: 700;
                    line-height: 1;
                    margin: 0;
                    text-align: center;
                }
                h3 {
                    font-weight: 200;
                    text-align: center;
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                    color: colors.get("light");
                }
                a {
                    padding: 10px 20px;
                    border: none;
                    border-radius: 5px;
                    color: colors.get("light");
                    background-color: colors.get("blue");
                    transition: background-color 0.3s;
                    &:hover {
                        background-color: colors.get("orange", light);
                    }
                }
            }
            .hero-img {
                width: 50%;
                height: 70%;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    /* transform: scale(1.8) translateX(21%) translateY(-7%); */
                }
            }
        }
    }

    #about-us {
        width: 100%;
        height: 60vh;
        min-height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 20px solid colors.get("dark", dark);
        .container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .au-img {
                width: 30%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                padding: 30px;
                background-color: colors.getColorWithOpacity(
                    "yellow",
                    light,
                    0.3
                );
                svg {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 100%;
                    height: auto;
                    z-index: 1;
                    transform: translate(-50%, -50%);
                }
                img {
                    position: relative;
                    width: 100%;
                    height: fit-content;
                    z-index: 2;
                }
            }
            .au-content {
                width: 70%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: end;
                justify-content: space-around;
                //padding: 30px 5rem 30px;
                border-left: 20px solid colors.get("dark", dark);
                background-color: colors.getColorWithOpacity(
                    "green",
                    light,
                    0.3
                );
                h5 {
                    width: 60%;
                    margin: 0;
                    margin-right: auto;
                    padding: 10px;
                    border-radius: 2px;
                    text-align: center;
                    background-color: colors.getColorWithOpacity(
                        "dark",
                        dark,
                        1
                    );
                    font-weight: 700;
                    color: colors.getColorWithOpacity("light", light, 1);
                }
                p {
                    width: 90%;
                    margin: 0 auto;
                    //text-align: center;
                    letter-spacing: 1px;
                    text-align: right;
                    line-height: 2rem;
                    padding: 30px;
                    //text-justify: distribute-all-lines;
                    padding-bottom: 1rem;
                    color: colors.getColorWithOpacity("blue", dark, 1);
                }
                a {
                    width: 60%;
                    text-align: center;
                    padding: 10px 20px;
                    border-radius: 5px 0 0 5px;
                    color: colors.get("blue");
                    border: 5px solid colors.get("blue");
                    border-right: none;
                    transition: all 0.3s;
                    font-weight: 700;
                    &:hover {
                        background-color: colors.get("blue");
                        color: colors.get("light");
                        width: 70%;
                    }
                }
            }
        }
    }

    #services {
        width: 100%;
        .container {
            width: 100%;
            #services-title {
                padding: 2rem;
                //border-bottom: 10px solid colors.get("dark");
                background-color: colors.get("dark");
                h2,
                h5 {
                    font-weight: 700;
                    text-align: center;
                    margin: 0;
                }
                h2 {
                    color: colors.get("light");
                }
                h5 {
                    color: colors.get("orange");
                }
            }
            .card-container {
                ul {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin: 0;
                    padding: 0;
                    li {
                        list-style: none;
                        overflow: hidden;
                        width: 50%;
                        height: 400px;
                        transition: all 0.5s linear;
                        border: 10px solid colors.get("dark");
                        position: relative;
                        .img-box {
                            width: 100%;
                            height: 100%;

                            padding: 5%;
                            aspect-ratio: 1 / 1;

                            position: relative;
                            display: flex;

                            position: absolute;
                            background-color: colors.getColorWithOpacity(
                                "blue",
                                light,
                                0.3
                            );
                            .icon-front {
                                height: 80%;
                                min-height: 80px;
                                width: auto;
                                z-index: 2;

                                @include mq-media(">desktop-large") {
                                    height: 130%;
                                }
                            }
                        }
                        .info-box {
                            position: absolute;
                            padding: 15px 0;
                            width: 95%;
                            height: 100%;
                            text-align: right;
                            display: flex;
                            flex-direction: column;
                            background-color: colors.getColorWithOpacity(
                                "orange",
                                light,
                                0.3
                            );

                            h5 {
                                background-color: colors.getColorWithOpacity(
                                    "dark",
                                    dark,
                                    1
                                );
                                margin: 0;
                                height: auto;
                                padding: 5px 20px;
                                color: colors.get("light");
                            }
                            p {
                                width: 50%;
                                overflow: auto;
                                height: 45%;
                                margin: 0;
                                padding: 10px;
                                color: colors.get("dark", light);
                            }
                            a {
                                height: auto;
                                padding: 10px;
                                color: colors.get("blue", dark);
                                transition: all 0.2s linear;
                                span {
                                    transition: all 0.2s linear;
                                }
                                &:hover {
                                    color: colors.get("blue");
                                    transform: scale(1.05);
                                    span:nth-child(2) {
                                        margin-left: 5px;
                                    }
                                }
                            }
                        }
                        &#first {
                            .img-box {
                                clip-path: polygon(
                                    0 0,
                                    0% 100%,
                                    100% 100%
                                ); // modificabile
                                align-items: end; //modificabile
                                justify-content: start; //modificabile
                                bottom: 0px; //modificabile
                                left: 0px; //modificabile
                                .icon-front {
                                    transform: scale(1.2) rotate(30deg); //modificabile
                                }
                            }
                            .info-box {
                                right: 0px; //modificabile
                                top: 0px; //modificabile
                                clip-path: polygon(
                                    0 0,
                                    100% 100%,
                                    100% 0
                                ); //modificabile
                                align-items: end; //modificabile

                                p {
                                    text-align: right;
                                }
                            }
                        }
                        &#second {
                            .img-box {
                                clip-path: polygon(
                                    100% 100%,
                                    100% 0,
                                    0 0
                                ); // modificabile
                                align-items: start; //modificabile
                                justify-content: end; //modificabile
                                bottom: 0px; //modificabile
                                right: 0px; //modificabile
                                .icon-front {
                                    transform: scale(1.1) rotate(30deg); //modificabile
                                }
                            }
                            .info-box {
                                left: 0px; //modificabile
                                bottom: 0px; //modificabile
                                clip-path: polygon(
                                    0 100%,
                                    100% 100%,
                                    0 0
                                ); //modificabile
                                align-items: start; //modificabile
                                justify-content: end;

                                p {
                                    text-align: left;
                                }
                            }
                        }
                        &#third {
                            .img-box {
                                clip-path: polygon(
                                    0 0,
                                    0 100%,
                                    100% 0
                                ); // modificabile
                                align-items: start; //modificabile
                                justify-content: start; //modificabile
                                bottom: 0px; //modificabile
                                left: 0px; //modificabile
                                .icon-front {
                                    transform: scale(1.2) rotate(-30deg); //modificabile
                                }
                            }
                            .info-box {
                                right: 0px; //modificabile
                                bottom: 0px; //modificabile
                                clip-path: polygon(
                                    0 100%,
                                    100% 100%,
                                    100% 0
                                ); //modificabile
                                justify-content: end;
                                align-items: end; //modificabile
                                p {
                                    text-align: right;
                                }
                            }
                        }
                        &#fourth {
                            .img-box {
                                clip-path: polygon(
                                    100% 100%,
                                    100% 0,
                                    0 100%
                                ); // modificabile
                                align-items: end; //modificabile
                                justify-content: end; //modificabile
                                bottom: 0px; //modificabile
                                right: 0px; //modificabile
                                .icon-front {
                                    transform: scale(1.2) rotate(-30deg); //modificabile
                                }
                            }
                            .info-box {
                                text-align: left;
                                left: 0px; //modificabile
                                top: 0px; //modificabile
                                clip-path: polygon(0 100%, 100% 0, 0 0);
                                //modificabile
                                justify-content: start;
                                align-items: start; //modificabile
                                p {
                                    text-align: left;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #cta-section {
        @include mixin.cta-section;
    }
}

@include mq-media(">desktop-large") {
    #home {
        .hero {
            .container {
                .hero-img {
                    img {
                        transform: scale(1.5) translateX(8%) translateY(-7%);
                    }
                }
            }
        }
        #services {
            .container {
                .card-container {
                    ul {
                        li {
                            .img-box {
                                .icon-front {
                                    height: 130%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include mq-media("<desktop-medium") {
    #home {
        .hero {
            .container {
                .hero-img {
                    img {
                        transform: scale(1.5) translateX(0%) translateY(-11%);
                    }
                }
            }
        }
    }
}

@include mq-media("<=desktop") {
    #home {
        .hero {
            .container {
                padding: 5%;
            }
        }
        #cta-section {
            @include mixin.cta-section-1150px;
        }
        #about-us {
            .container {
                .au-content {
                    h5 {
                        width: 100%;
                    }
                    p {
                        font-size: 12px;
                    }
                }
            }
        }
        #services {
            .container {
                width: 100%;
                .card-container {
                    width: 100%;
                    ul {
                        flex-wrap: wrap;
                        li {
                            height: 450px;
                            width: 50%;
                            .img-box {
                                width: 80%;
                                .icon-front {
                                    height: 50%;
                                    min-height: unset;
                                }
                            }
                            .info-box {
                                height: 115%;
                                p,
                                h5 {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include mq-media("<=tablet") {
    #home {
        .hero {
            height: calc(100vh - 100px);
            #bg-img2,
            #bg-img3 {
                height: calc(100vh - 50px);
            }
            .container {
                height: 100%;
                margin-top: 0;
                flex-direction: column;
                .hero-title,
                .hero-img {
                    width: 100%;
                    height: 50%;
                }
                .hero-title {
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                .hero-img {
                    img {
                        transform: none;
                    }
                }
            }
            .low-wave {
                bottom: 0px;
            }
        }
        #about-us {
            .container {
                width: 100%;
                //flex-direction: column;
                .au-img {
                    width: 50%;
                    padding-right: 0;
                }
                .au-content {
                    width: 100%;
                    text-align: center;
                    padding: 0;
                    p {
                        line-height: unset;
                    }
                }
            }
        }
        #cta-section {
            @include mixin.cta-section-850px;
        }
        #services {
            .container {
                width: 100%;
                .card-container {
                    width: 100%;
                    ul {
                        width: 100%;
                        padding: 0;
                        flex-wrap: wrap;
                        padding: 0;
                        li {
                            height: 400px;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@include mq-media("<=tablet-small") {
    #home {
        #services {
            .container {
                .card-container {
                    ul {
                        li {
                            height: 500px;
                        }
                    }
                }
            }
        }
    }
}

@include mq-media("<=phone") {
    #home {
        #about-us {
            .container {
                .au-content {
                    h5 {
                        margin: 0;
                        font-size: 11px;
                    }
                    p {
                        padding: 0;
                        letter-spacing: unset;
                        font-size: 10px;
                    }
                    .btn-shadow {
                        padding: 0 20px;
                        width: 100%;
                        border-right: 5px solid colors.get("blue");
                    }
                }
            }
        }
        #cta-section {
            @include mixin.cta-section-500px;
        }
        #services {
            .container {
                width: 100%;
                .card-container {
                    width: 100%;
                    ul {
                        width: 100%;
                        flex-wrap: wrap;
                        padding: 0;
                        li {
                            width: 100%;
                            height: 400px;
                            &#second {
                                .info-box {
                                    flex-direction: column-reverse;
                                }
                            }
                            &#third {
                                .info-box {
                                    flex-direction: column-reverse;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
