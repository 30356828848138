@use "./colors" as colors;
@use "./fonts" as fonts;
@use "../third-party/media-query" as mq;

@for $i from 1 through 5 {
    .h1-bigger-#{$i} {
        font-size: fonts.getSize(h1, $i);
    }
}

@each $name, $map in colors.$colors {
    .highlight-#{$name} {
        color: colors.get($name);
    }
}

.container {
    width: 60%;
    min-width: 1140px;
    margin: 0 auto;
    @include mq.media("<=1150px") {
        min-width: 0;
        width: 100%;
    }
    @include mq.media("<=1140px") {
        width: auto;
    }
}

.btn-shadow {
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
        0 1px 5px 0 rgb(0 0 0 / 20%);
    transition: box-shadow 0.3s;
    &:hover {
        box-shadow: 0 3px 3px 0 rgb(0 0 0 / 14%), 0 1px 7px 0 rgb(0 0 0 / 12%),
            0 3px 1px -1px rgb(0 0 0 / 20%);
    }
}

.border-top-dark-10 {
    border-top: 10px solid colors.get("dark", dark) !important;
}
.border-left-dark-10 {
    border-left: 10px solid colors.get("dark", dark) !important;
}
.border-right-dark-10 {
    border-right: 10px solid colors.get("dark", dark)  !important;
}
.border-bottom-dark-10 {
    border-bottom: 10px solid colors.get("dark", dark)  !important;
}
.border-y-dark-10 {
    border-top: 10px solid colors.get("dark", dark)  !important;
    border-bottom: 10px solid colors.get("dark", dark)  !important;
}
.border-x-dark-10 {
    border-left: 10px solid colors.get("dark", dark)  !important;
    border-right: 10px solid colors.get("dark", dark)  !important;
}
.border-dark-10 {
    border-top: 10px solid colors.get("dark", dark)  !important;
    border-bottom: 10px solid colors.get("dark", dark)  !important;
    border-left: 10px solid colors.get("dark", dark)  !important;
    border-right: 10px solid colors.get("dark", dark)  !important;
}
.border-top-dark-20 {
    border-top: 20px solid colors.get("dark", dark)  !important;
}
.border-left-dark-20 {
    border-left: 20px solid colors.get("dark", dark)  !important;
}
.border-right-dark-20 {
    border-right: 20px solid colors.get("dark", dark)  !important;
}
.border-bottom-dark-20 {
    border-bottom: 20px solid colors.get("dark", dark)  !important;
}
.border-y-dark-20 {
    border-top: 20px solid colors.get("dark", dark)  !important;
    border-bottom: 20px solid colors.get("dark", dark)  !important;
}
.border-x-dark-20 {
    border-left: 20px solid colors.get("dark", dark)  !important;
    border-right: 20px solid colors.get("dark", dark)  !important;
}
.border-dark-vertical-20 {
    border-top: 10px solid colors.get("dark", dark)  !important;
    border-bottom: 10px solid colors.get("dark", dark)  !important;
    border-left: 20px solid colors.get("dark", dark)  !important;
    border-right: 20px solid colors.get("dark", dark)  !important;
}
.border-dark-horizontal-20 {
    border-top: 20px solid colors.get("dark", dark)  !important;
    border-bottom: 20px solid colors.get("dark", dark)  !important;
    border-left: 10px solid colors.get("dark", dark)  !important;
    border-right: 10px solid colors.get("dark", dark)  !important;
}
.border-dark-20 {
    border-top: 20px solid colors.get("dark", dark)  !important;
    border-bottom: 20px solid colors.get("dark", dark)  !important;
    border-left: 20px solid colors.get("dark", dark)  !important;
    border-right: 20px solid colors.get("dark", dark)  !important;
}



#pop-up-privacy-policy {
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 30%;
    height: auto;
    padding: 20px;
    background-color: colors.get("dark", light);
    border-radius: 4px;
    z-index: 1000;

    @include mq.media("<=1350px") {
        width: 60%;
    }

    @include mq.media("<=850px") {
        width: 100%;
        right: 0;
        bottom: 50px;
        border-radius: 0;
        z-index: 900;
        background-color: colors.getColorWithOpacity(dark, light, 0.8);
        backdrop-filter: blur(2px);
    }

    p {
        margin: 0;
        margin-bottom: 15px;
        color: colors.get("light");
        @include mq.media("<=850px") {
            font-size: fonts.getSize(small);
        }

        a {
            text-decoration: none;
            color: colors.get("blue");
            font-weight: bold;
        }
    }

    div {
        width: 50%;
        margin-left: auto;
        display: flex;
        justify-content: space-around;
        @include mq.media("<=850px") {
            width: 100%;
        }

        button {
            width: 40%;
            border: none;
            padding: 5px 10px;
            border-radius: 4px;
            cursor: pointer;
            &:nth-child(1) {
                background-color: colors.get("blue");
                color: colors.get("light");
                transition: background-color 0.3s;
                &:hover {
                    background-color: colors.get("blue", light);
                }
            }
            &:nth-child(2) {
                background-color: colors.get("light");
                transition: background-color 0.3s;
                &:hover {
                    background-color: colors.get("light", light);
                }
            }
        }
    }
}

.center-xy {
    display: flex;
    align-items: center;
    justify-content: center;
}
