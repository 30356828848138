@use "../third-party/normalizer";

@use "./utilities";
@use "./scrollbar";
@use "./typography";
@use "./fonts" as fonts;

@use "../components/header";
@use "../components/footer";

@use "animate.css";

@forward "../third-party/media-query" as mq-*;

* {
    text-decoration: none;
    font-family: fonts.get(sans-serif);
    box-sizing: border-box;
}
@media (-webkit-device-pixel-ratio: 1.25) {
    body {
        zoom: 0.7;
    }
}
@media (-webkit-device-pixel-ratio: 1.5) {
    body {
        zoom: 0.6;
    }
}
@media (-webkit-device-pixel-ratio: 1.75) {
    body {
        zoom: 0.6;
    }
}
