@use "../utilities/colors" as colors;
@use "../utilities/fonts" as fonts;
@use "../utilities/mixins" as mixins;

@use "../third-party/media-query" as mq;

header.pc {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 90px;
    background-color: transparent;
    #navbar {
        height: 100%;
        width: 90%;
        margin: 0 auto;
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        #logo,
        #logo-fixed {
            @include mixins.header-logo;
            height: 100%;
            display: flex;
            align-items: center;
        }
        #logo-fixed {
            display: none;
        }
        #navlist {
            height: 100%;
            width: 25%;
            min-width: 400px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            list-style: none;
            li {
                .link {
                    font-size: 20px;
                    @include mixins.underline-link(light, 1);
                }
            }
        }
    }

    &.header-fixed {
        position: fixed;
        background-color: colors.get("dark", dark);
        box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
        animation: slide-in 0.2s linear forwards;
        #navbar {
            #logo {
                display: none;
            }
            #logo-fixed {
                height: 100%;
                display: flex;
                align-items: center;
            }
            #navlist {
                li {
                    .link {
                        @include mixins.underline-link(light, 1);
                    }
                }
            }
        }
    }
}

header.small {
    display: none;
    position: fixed;
    background-color: white;
    box-shadow: 0 -3px 5px rgba(57, 63, 72, 0.1);
    bottom: 0;
    left: 0;
    z-index: 300;
    width: 100%;
    height: 50px;
    transition: all 0.2s linear;
    #navbar {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: transparent;
        #logo {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-weight: 700;
            font-size: fonts.getSize(h3);
            #logo-item {
                height: 100%;
                width: 50%;
                img {
                    height: 100%;
                    width: auto;
                }
            }
            .menu-item {
                display: none;
                font-size: fonts.getSize(small);
                position: relative;
                width: 50%;
                text-align: center;

                a {
                    background-color: white;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    position: absolute;
                    top: -60px;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 250;
                    animation: slide-up-v2 0.3s ease-out;
                    svg {
                        fill: none;
                        stroke: colors.get("dark");
                    }
                }
            }
            .menu-item.first,
            .menu-item.last {
                margin-top: 3%;
                a {
                    top: -50px;
                }
            }
            svg {
                height: 30px;
                width: 30px;
                fill: colors.get("dark");
            }
            #hamburger-menu {
                position: absolute;
                right: 5%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 350;
            }
        }
    }
}

#back-layer {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: colors.getColorWithOpacity(dark, base, 0.4);
    backdrop-filter: blur(3px);
    z-index: 200;
}

@keyframes slide-in {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0%);
    }
}

@keyframes slide-up-v1 {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0%);
    }
}

@keyframes slide-up-v2 {
    from {
        transform: translate(-50%, 100%);
    }
    to {
        transform: translate(-50%, -50%);
    }
}

@keyframes slide-down {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(200%);
    }
}
@keyframes opacity-on {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@include mq.media("<=desktop-medium") {
    header {
        #navbar {
            justify-content: flex-start;
        }
    }
}

@include mq.media("<=desktop") {
    header.pc {
        #navbar {
            width: auto;
            #logo {
                width: 40%;
                span {
                    font-size: 40px;
                }
            }
            #navlist {
                width: 60%;
            }
        }
    }
}

/* SWITCH VERSIONE HEADER */
@include mq.media("<=tablet") {
    header.pc {
        display: none;
    }
    header.small {
        display: block;
    }
}

@include mq.media("<=phone") {
    header.small {
        #navbar {
            #logo {
                span {
                    font-size: fonts.getSize(h4);
                }
            }
        }
    }
}
