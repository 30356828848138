@use "./colors" as colors;
@use "./fonts" as fonts;

@use "../third-party/media-query" as mq;

@mixin underline-link($color, $opacity) {
    position: relative;
    opacity: $opacity;
    color: colors.get($color);
    transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    &:hover {
        opacity: 1;

        &::after {
            width: 100%;
            left: 0;
            background: colors.get($color);
        }
    }

    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 1px;
        display: block;
        right: 0;
        background: colors.get($color);
        transition: width 0.2s linear;
    }
}

@mixin header-logo {
    svg {
        height: 100%;
        width: auto;
    }
    img {
        height: 80%;
        width: auto;
    }
    @include mq.media("<=1150px") {
        margin-left: 15px;
    }
}

@mixin page-hero {
    position: relative;
    width: 100%;
    height: calc(100vh - 20px);
    background-image: url("../immagini/background_services_with_text.png");
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    overflow: hidden;
    position: relative;
    .hero-slogan {
        color: #127eaa;
        padding: 0;
        margin: 0;
        font-weight: 900;
        font-size: 80px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .hero-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba($color: #000000, $alpha: 0.3);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 12px 20px 20px;
        h1 {
            color: rgba(255, 230, 69, 0.75);
            padding: 0;
            margin: 0;
            font-weight: 900;
        }
        h3 {
            color: white;
            text-align: center;
            padding: 0 12px 4px;
            margin: 0;
            strong {
                color: rgba(255, 230, 69, 0.75);
            }
        }
    }
}

@mixin article-list {
    /* padding: 50px; */
    .container {
        width: 100%;
        article {
            /* padding: 5rem 0; */
            height: 60vh;
            min-height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top: 10px solid colors.get("dark", dark);
            border-bottom: 10px solid colors.get("dark", dark);
            border-left: 20px solid colors.get("dark", dark);
            border-right: 20px solid colors.get("dark", dark);
            .img_container {
                display: flex;
                justify-content: center;
                width: 45%;
                height: 100%;
                padding-top: 6px;
                padding-bottom: 6px;
                background-color: rgba(255, 230, 69, 0.3);
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .mini-title-container-left {
                display: flex;
                justify-content: start;
                width: 100% !important;
                padding: 0;
                margin-top: 14px;
                max-height: 90px;
            }
            .mini-title-container-right {
                display: flex;
                justify-content: end;
                width: 100% !important;
                padding: 0 !important;
                margin-top: 14px;
                max-height: 90px;
            }
            div {
                width: 50%;
                height: 100%;
                padding: 0 4rem;
                img {
                    height: 100%;
                    width: auto;
                    max-width: 500px;
                }
                h2 {
                    font-weight: 700;
                    color: #f1f3ff;
                    display: inline-flex;
                    align-items: center;
                    padding: 0px 12px;
                    background-color: colors.get("dark", dark);
                    margin: 0;
                }
                h4 {
                    margin: 0;
                    color: colors.get("dark", dark);
                    display: inline-block;
                }
                p {
                    color: #1a237e;
                    font-weight: 400;
                }
                &.text-right {
                    text-align: right;
                    width: 55%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                &.text-left {
                    width: 55%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }
    }
}

.text-left {
    background-color: rgba(238, 96, 54, 0.3) !important;
}

.text-right {
    background-color: rgba(46, 156, 204, 0.3) !important;
}

@mixin cta-section {
    padding: 50px;
    background-color: rgba(46, 156, 204, 0.3) !important;

    .container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        h1 {
            text-align: center;
            color: colors.get("dark", dark);
            font-weight: 600;
            margin: 0;
        }
        a {
            padding: 10px 40px;
            border: none;
            font-size: fonts.getSize(h3);
            border-radius: 5px;
            color: colors.get("light");
            background-color: colors.get("blue");
            cursor: pointer;
            transition: background-color 0.3s;
            &:hover {
                background-color: colors.get("blue", light);
            }
        }
    }
}

@mixin cta-section-1150px {
    .container {
        flex-direction: column;
    }
}

@mixin cta-section-850px {
    padding: 30px 5px;
    .container {
        h1 {
            font-size: fonts.getSize(h3);
            margin-bottom: 1rem;
        }
        a {
            font-size: fonts.getSize(h5);
        }
    }
}

@mixin cta-section-500px {
    .container {
        h1 {
            font-size: fonts.getSize(h4);
        }
        a {
            font-size: fonts.getSize(p);
            padding: 5px 20px;
        }
    }
}
