@use "./fonts" as fonts;
@use "./colors" as colors;

@use "../third-party/media-query" as mq;

html {
    font-size: 100%;
    line-height: fonts.$font-line-height;
    color: colors.get("dark");
    overflow-x: hidden;
    background-color: colors.get("light");
    @include mq.media("<=phone") {
        font-size: 81.3%;
    }
    @include mq.media(">phone", "<=tablet") {
        font-size: 93.8%;
    }
}

p {
    margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 3rem 0 1.38rem;
    font-weight: 400;
}

h1 {
    margin-top: 0;
    font-size: fonts.getSize(h1);
}

h2 {
    font-size: fonts.getSize(h2);
}

h3 {
    font-size: fonts.getSize(h3);
}

h4 {
    font-size: fonts.getSize(h4);
}

h5 {
    font-size: fonts.getSize(h5);
}

small,
.text_small {
    font-size: fonts.getSize(small);
}
